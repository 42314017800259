@font-face {
  font-family: Lexend;
  src: url(../../assets/styles/Lexend-VariableFont_wght.ttf);
}
.sidebar__scroll {
  max-height: calc(100vh - 145px); /* Adjust the value based on your layout */
  overflow-y: auto; /* Ensure that the container has a scrollbar */
  scrollbar-width: thin; /* "thin" is the default value for browsers that support the standard scrollbar model */
  scrollbar-color: #d9d9d9 #fff; /* Change both the track and thumb color */
  overflow-x: hidden;
  /* Webkit-based browsers (Chrome, Safari, Opera) */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 11px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 11px;
  }
}

.sidebar__nav-item {
  text-decoration: none;
  color: #000000;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */

  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow text */
  transition: width 0.3s ease; /* Add transition property for width */
}
.sidebar__nav-item.active {
  border-radius: 5px;
  color: #251163;
  background: var(--bg, rgba(37, 17, 99, 0.1));
}
.sidebar__nav-item:hover {
  border-radius: 5px;
  color: #251163 !important;
  box-shadow: 0px 0px 2px 0px #00000026;
}
.sidebar__nav-title {
  color: #292d32;
  text-align: center;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  text-transform: uppercase;
}

.sidebar__logo {
  width: 70px;
  height: 32px;
  margin-top: 25px;
  margin-left: 20px;
  transition: width 0.3s ease;
}
.sidebar__logo-min {
  width: 45px;
  height: 23px;
  margin-top: 25px;
  justify-self: center;
  transition: width 0.3s ease;
}

.sidebar__nav-section {
  padding: 0 20px;
}

.sidebar__search-input {
  color: var(--Gray-Text, #808080);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 19.6px */
  text-decoration-line: underline;
}

.sidebar__search-empty {
  color: var(--Gray-Text, #808080);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 19.6px */
}

.sidebar__search-container {
  margin: 1rem 0 1rem 1.8rem;
}
.sidebar__search-container-min {
  margin-left: 0px;
}

.sidebar__main {
  z-index: 999;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  transition:
    width 0.3s ease,
    margin-left 0.3s ease; /* Add transition properties */
}

.sidebar__toggle {
  position: fixed;
  top: 70px;
  left: 15rem;
  transition: left 0.3s ease; /* Add transition property */
}

.sidebar__toggle-min {
  top: 60px;
  left: 5rem;
  transition: left 0.3s ease; /* Add transition property */
}
.sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 900; /* Ensure it's above other content */
}
