// New Colors
$ubbe_blue: #25215f;
$ubbe_yellow: #ffcf01;
$ubbe_yellow_75: #ffdb40;
$ubbe_yellow_55: #ffe573;
$ubbe_yellow_light: #fffef8;
$ubbe_dark_black: #333333;
$ubbe_order_sand: #ffe388;
$ubbe_extra_light: #fff3d0;

// Old Colors
$gobox_dark_blue: #141b4d;
$gobox_light_blue: #385e9d;
$gobox_grey: #5a5a5a;
$mobil_red: #fe000c;

.dark_blue {
  color: $ubbe_blue;
}

.grey {
  color: $gobox_grey;
}

.red {
  color: $mobil_red;
}

.ubbe-blue {
  color: $ubbe_blue;
}

.ubbe-yellow {
  color: $ubbe_yellow;
}

.ubbe-yellow-light {
  color: $ubbe_yellow_light;
}

.ubbe-yellow-bg {
  background-color: $ubbe_yellow;
}

.ubbe-blue-bg {
  background-color: $ubbe_blue;
}

.ubbe-red-bg {
  background-color: $mobil_red;
}
